import React from 'react';
import PageCsv from '../components/Csv/PageCsv';

const SPREADSHEET = '12bVHSZT2Qc5Lslf5RXL2xealjZTHN3oQ1r4IeEsku5s';
const SHEET_ID = 'Verbs';
const TITLE = 'Verbs';
const RANGE = 'A1:B';

export default function Page () {
  const RANGE_COMPLETE = SHEET_ID ? `${SHEET_ID}!${RANGE}` : RANGE;
  return (
    <PageCsv
      title={TITLE}
      spreadsheetId={SPREADSHEET}
      sheetId={SHEET_ID}
      range={RANGE_COMPLETE}
    />
  )

}
